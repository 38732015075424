/* eslint-disable radix */
import React, {useState, useRef, useEffect} from 'react'
import { gsap } from "gsap";
import { navigate } from 'gatsby-link';

export default function Menu(props) {

    let tl = useRef(null)
    let menu = useRef(null)
    let overlayWrapper = useRef(null)
    let aboutText = useRef(null)
    let workText = useRef(null)
    let contactText = useRef(null)
    let homeText = useRef(null)
    let showOverlay = false
    let animating = false

    const createTimeline = () => {

        let ease = 'Power4.easeOut'
            tl.current = gsap.timeline({paused: true, onStart: () => {animating = true}, onComplete: () => {animating = false}})
            .from(['#menu_container', '.menu_line'], .25, {ease: ease, autoAlpha: 0}, 0)

    }

    useEffect(() => {

        createTimeline()

        return () => {
            tl.current.kill()
        }
    }, [])

    useEffect(() => {
        if (props.stageBuilt === true){
            tl.current.play()
        }
    }, [props.stageBuilt])

    useEffect(() => {
        if (props.reverseComplete === true){
            tl.current.play()
        }
    }, [props.reverseComplete])

    useEffect(() => {
        if (props.static === true){
            tl.current.play()
        }
    }, [])

    let routingObj = {
        about: () => {
           props.fromHome ? props.linkLogic.handleClick1() : navigate('/about')
        },
        work: () => {
            props.fromHome ? props.linkLogic.handleClick2() : navigate('/work')
        },
        contact: () => {
            props.fromHome ? props.linkLogic.handleClick3() : navigate('/contact')
        },
        home: () => {
            if (!props.fromHome) navigate('/')
        }
    }

    const handleToggle = (link) => {

        if (animating === false){
                animating = true
                showOverlay = !showOverlay
                let ease = 'Power1.easeOut'
        
                if (showOverlay === true){
                    // gsap.set(overlayContainer, {position: 'fixed', zIndex: 5})
                    gsap.set('.text', {y: -20})
                    gsap.to(overlayWrapper, .25, {ease: ease, autoAlpha: 1})
                    gsap.to('.text', .25, {ease: ease, y: 0, stagger: .1, autoAlpha: 1, delay: 0, onComplete: () => {animating = false}})
                    //gsap.to(menu, .5, {ease: ease, autoAlpha: 0})
                    tl.current.reverse()
                }
                else {
                    // gsap.set(overlayContainer, {position: 'absolute', zIndex: 0})
                    gsap.to('.text', .25, {ease: ease, y: 20, stagger: .1, autoAlpha: 0})
                    gsap.to(overlayWrapper, .25, {ease: ease, autoAlpha: 0, delay: .3})
                    gsap.to(menu, .25, {ease: ease, autoAlpha: 1, delay: .55, onComplete: () => {
                        animating = false
                        if (link !== undefined && link !== props.page){
                            routingObj[link]()
                        }
                        else {
                            tl.current.play()
                        }
        
                    }})
                }
        }

    }


    const textHover = (idx, direction) => {

        let hasEntered = direction
        let textEls = [aboutText, workText, contactText, homeText]
        let el = textEls[idx]

        if (hasEntered){
            gsap.to(el, 0.5, {ease: 'Power4.easeOut', color: '#D62732'})
        }
        else {
            gsap.to(el, 0.5, {ease: 'Power4.easeOut', color: '#fffcd7'})
        }

    }

  return (
    <div id="menu_main" >
        <div id="menu_container" onClick={() => handleToggle()} ref={el => {menu = el}} >
            <div className="menu_line" id="menu_top" />
            <div className="menu_line" id="menu_middle" />
            <div className="menu_line" id="menu_bottom" />
        </div>
        <div id="menu_overlay_container" >
            <div ref={el => {overlayWrapper = el}} id="overlay_wrapper" onClick={(e) => {
            if (e.target === overlayWrapper){
                handleToggle()
            }
        }
        }>
                <div id="menu_overlay" />
            </div>
            <div id="menu_options_container">
                <h1 className='text' 
                ref={el => {aboutText = el}}
                 onMouseEnter={() => textHover(0, true)}
                onMouseLeave={() => textHover(0, false)}
                onClick={(e) => {
                    if (e.target === aboutText) handleToggle('about')}
                    }>
                About
                </h1>
                <h1 className='text'
                ref={el => {workText = el}}
                onMouseEnter={() => textHover(1, true)}
                onMouseLeave={() => textHover(1, false)}
                onClick={(e) => {
                    if (e.target === workText) handleToggle('work')}
                    }>
                Work
                </h1>
                <h1 className='text'
                ref={el => {contactText = el}}
                onMouseEnter={() => textHover(2, true)}
                onMouseLeave={() => textHover(2, false)}
                onClick={(e) => {
                    if (e.target === contactText) handleToggle('contact')}
                    }>
                Contact
                </h1>
                <h1 className='text'
                ref={el => {homeText = el}}
                onMouseEnter={() => textHover(3, true)}
                onMouseLeave={() => textHover(3, false)}
                onClick={(e) => {
                    if (e.target === homeText) handleToggle('home')}
                    }>
                Home
                </h1>
            </div>
        </div>
    </div>
  )
}
